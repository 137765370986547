import React from 'react';
import { Link } from 'gatsby';

import Layout from '../layout/main';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/adaptix.jpg';

import Fade from 'react-reveal/Fade';

const ContactPage = () => (
	<Layout>
		<SEO
			title="Contact | Strong Mind Performance Consulting"
			description="Contact us and find out how we can start building your Strong Mind."
			image={ogImage}
		/>
		<section id="contact">
			<Fade>
				<form name="Contact Form" method="POST" data-netlify="true" action="/thankyou">
					<input type="hidden" name="form-name" value="Contact Form" />
					<div className="contact__intro">
						<h2 className="shout">Are you ready to get started?</h2>
						<h3 className="declare">Contact us and find out how we can start building your Strong Mind.</h3>
					</div>
					<p className="talk color__ink" />
					<div className="contact__field">
						<label>Your name:</label>
						<input id="contactName" placeHolder="First and Last Name" type="text" name="name" required />
					</div>
					<div className="contact__field">
						<label>Your email:</label>
						<input
							id="contactEmail"
							placeHolder="Email address"
							type="email"
							name="email"
							autocomplete="off"
							required
						/>
					</div>
					<div className="contact__field">
						<label>You age:</label>
						<input id="contactAge" placeHolder="Age" type="text" name="age" required />
					</div>
					<div className="contact__field">
						<label>Your sport:</label>
						<input id="contactSport" placeHolder="Sport" type="text" name="sport" required />
					</div>
					<div className="contact__field contact__field-textarea ">
						<label>Your Message:</label>
						<textarea
							id="contactMessage"
							placeHolder="What are you hoping to acheive working with Strong Mind?"
							name="message"
							required
						/>
					</div>
					<button className="btn btn__accent" type="submit">
						Send
					</button>
				</form>
			</Fade>
		</section>
	</Layout>
);

export default ContactPage;
